import React from 'react';

import './avatar.css';
import NotUser from '../../assets/img/notUser.png'

const Avatar = ({image}) => {

    return (
        <div className='containerAvatar'>
            <img className={'imageNavBar'} alt={''} src={image ? `https://node.mybeatcoach.com/${image}` : NotUser}/>

            <div className='dotAvatarConnected'></div>
        </div>
    )
}

export default Avatar;
