import React, {useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom';

import Chat from '../views/chat/chat';
import Rating from '../views/rating/rating';
import ReportUser from '../views/reportUser/reportUser';
import Articles from '../views/articles/articles';
import Article from '../views/article/article';
import Profile from '../views/profile/profile';
import Users from '../views/users/users';
import Rates from '../views/rates/rates';

import NotFound from '../views/notFound/notFound';
import Coaches from '../views/coaches/coaches';
import Company from '../views/company/company';
import Specialties from '../views/specialties/specialties';
import Schedules from  '../views/schedules/schedules';
import EditSpecialty from '../views/specialties/editSpecialty/editSpecialty';
import AddUser from '../views/users/addUser/addUser';
import AddCoach from '../views/coaches/addCoach/addCoach';
import AddCompany from '../views/company/addCompany/addCompany';
import AddSpecialty from '../views/specialties/addSpecialty/addSpecialty';

import EditCoach from '../views/coaches/editCoach/editCoach';
import EditCompany from '../views/company/editCompany/editCompany';
import EditUser from '../views/users/editUser/editUser';
import ChatHistory from '../views/chatHistory/chatHistory';
import Calendar from '../views/calendar/calendar';
import GeneralStats from '../views/generalStats/generalStats';
import CoachStats from '../views/coachStats/coachStats';
import CompaniesStats from '../views/companiesStats/companiesStats';
import AddSection from '../views/articles/addSection/addSection';
import AddArticle from '../views/articles/addArticle/addArticle';
import EditSection from '../views/articles/editArticle/editSection';
import Bans from '../views/bans/bans';
import Holidays from '../views/holidays/holidays'
import Notifications from '../views/notifications/notifications'
import AddNotification from '../views/notifications/addNotification/addNotification';

// Router de las paginas una vez te has autenticado
const Router = () => {
        const [userType, setUserType] = useState(null);
        useEffect(() => {
            let user = localStorage.getItem('USER');
            setUserType(JSON.parse(user).user_type)
        }, [])

    return(
        <Routes>
                {userType !== 'admin' && <Route path={'/'} element={<Coaches/>}/>}
                {userType !== 'admin' && <Route path={'/chat'} element={<Chat/>}/>}
                {userType !== 'admin' && <Route path={'/rating'} element={<Rating/>}/>}
                {userType !== 'admin' && <Route path={'/report'} element={<ReportUser/>}/>}
                {userType !== 'admin' && <Route path={'/articles'} element={<Articles/>}/>}
                {userType !== 'admin' && <Route path={'/article'} element={<Article/>}/>}
                {userType !== 'admin' && <Route path={'/profile'} element={<Profile/>}/>}
                <Route path={userType !== 'admin' ? '/users' : '/'} element={<Users/>}/>
                {userType !== 'admin' && <Route path={'/rates'} element={<Rates/>}/>}
                {userType !== 'admin' && <Route path={'/coaches'} element={<Coaches/>}/>}
                {userType !== 'admin' && <Route path={'/companies'} element={<Company/>}/>}
                {userType !== 'admin' && <Route path={'/specialties'} element={<Specialties/>}/>}
                {userType !== 'admin' && <Route path={'/schedules'} element={<Schedules/>}/>}
                {userType !== 'admin' && <Route path={'/addUser'} element={<AddUser/>}/>}
                {userType !== 'admin' && <Route path={'/addCoach'} element={<AddCoach/>}/>}
                {userType !== 'admin' && <Route path={'/addCompany'} element={<AddCompany/>}/>}
                {userType !== 'admin' && <Route path={'/addSpecialty'} element={<AddSpecialty/>}/>}
                {userType !== 'admin' && <Route path={'/editSpecialty'} element={<EditSpecialty/>}/>}
                {userType !== 'admin' && <Route path={'/editCoach'} element={<EditCoach/>}/>}
                {userType !== 'admin' && <Route path={'/addSection'} element={<AddSection/>}/>}
                {userType !== 'admin' && <Route path={'/addArticle'} element={<AddArticle/>}/>}
                {userType !== 'admin' && <Route path={'/editSection'} element={<EditSection/>}/>}
                {userType !== 'admin' && <Route path={'/editCompany'} element={<EditCompany/>}/>}
                {userType !== 'admin' && <Route path={'/editUser'} element={<EditUser/>}/>}
                {userType !== 'admin' && <Route path={'/chatHistory'} element={<ChatHistory/>}/>}
                {userType !== 'admin' && <Route path={'/calendar'} element={<Calendar/>}/>}
                {userType !== 'admin' && <Route path={'/generalStats'} element={<GeneralStats/>}/>}
                {userType !== 'admin' && <Route path={'/coachStats'} element={<CoachStats/>}/>}
                <Route path={'/companiesStats'} element={<CompaniesStats/>}/>
                {userType !== 'admin' && <Route path={'/bans'} element={<Bans/>}/>}
                {userType !== 'admin' && <Route path={'/holidays'} element={<Holidays/>}/>}
                {userType !== 'admin' && <Route path={'/notifications'} element={<Notifications/>}/>}
                {userType !== 'admin' && <Route path={'/addNotification'} element={<AddNotification/>}/>}


            <Route path={'*'} element={<NotFound/>}/>
        </Routes>
    )
}

export default Router;
