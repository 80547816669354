import React, {useEffect, useState} from 'react';

import './login.css';
import {ImageAssets} from '../../../utils/ImageAssets';
import {IconAssets} from '../../../utils/ImageAssets';

import {useNavigate} from 'react-router-dom';
import {message} from 'antd';
import loginStore from "../../../store/loginStore";
import {login} from '../../../services/user.service';

import Input from '../../../components/input/input.compnent';
import Button from '../../../components/button/button.component';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [disable, setDisable] = useState(false);
    const setLogin = loginStore(state => state.setLogin);

    let navigate = useNavigate();

    // Hace el login
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // LLama al servidor enviando email y password para realizar el login
            login(email, password).then(res => {
                // Comprueba si el usuario es admin o superadmin para permitirle hacer el login
                if (res.data.user.user_type === 'admin' || res.data.user.user_type === 'superadmin') {
                    //localStorage.setItem('user', JSON.stringify(res.data))
                    setLogin(true);
                } else {
                    // Si el usuario no es admin ni superadmin avisa que el usuario no esta autorizado para entrar
                    message.error('Usuario no autorizado.');
                }
            }).catch(err => {
                message.error('No se ha podido realizar el login, credenciales incorrectas.');

            })
        } catch (err) {
            message.error('Error al iniciar sesión. Por favor, verifica tus credenciales.');
        }
      };

    // Se ejecuta  cada vez que cambia email o password
    useEffect(() => {
        // Si el email y el password no estan vacios el boton se activa sino se desactiva
        if (!email || !password) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [email, password]);

    // Si email esta lleno navega a la pagina para recordar la contraseña sino muestra un mensaje para que rellenes tu email y puedas ir a recordar contraseña
    const goRememberPassword = () => {
        if (email) {
            navigate('/rememberPassword', {state: {email: email}});
        } else {
            message.error('Introduce tu correo para recordar la contraseña.');
        }
    }


    return (
        <div className={'containerLogin'}>
            <img className={'logo'} alt={''} src={ImageAssets.originalLogoAuth}/>
            <form  onSubmit={handleSubmit} className={'formLogin'}>
                <Input
                    iconLeft={IconAssets.at}
                    placeholder={'Email'}
                    type={'text'}
                    changeValue={value => setEmail(value)}
                />

                <p style={{margin: 10}}></p>

                <Input
                    iconLeft={IconAssets.pass}
                    placeholder={'Contraseña'}
                    type={'password'}
                    changeValue={value => setPassword(value)}
                />

                <a style={{marginBottom: 20}} href='/rememberPassword' className={'rememberPasswordLogin'} onClick={goRememberPassword}>¿Olvidaste tu contraseña?</a>

                <Button
                    type="submit"
                    disabled={disable}
                    text={'Iniciar sesión'}

                />
            </form>
        </div>
    )
}

export default Login;
