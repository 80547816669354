import React, { useState, useRef, useEffect } from 'react';
import AddAvatar from '../../../components/avatar/addAvatar';
import AddButton from '../../../components/addButton/addButton.component';
import './editCoach.css';
import InputText from '../../../components/inputText/inputText.component';
import Button from '../../../components/button/button.component';
import TopNavBar from '../../../components/topNavBar/topNavBar.component';
import {
    OrganizationService,
    UserService,
    ImageService,
    CoachService,
} from '../../../services/mbc.service';
import DepartmentDropdown from '../../../components/departmentDropdown/departmentDropdown';
import CompaniesDropdown from '../../../components/companiesDropdown/companiesDropdown';
import SpecialtiesDropdown from '../../../components/specialtiesDropdown/specialtiesDropdown';
import Modal from '../../../components/modal/modalComponent';
import InputDateText from '../../../components/inputDateText/inputDateText';
import CountryDropdown from '../../../components/countryDropdown/countryDropdown.component';
import GeneralDropdown from '../../../components/generalDropdown/generalDropdown.component';
import { useLocation } from 'react-router-dom';
import { ImagesUrl } from '../../../utils/ImageAssets';
import {Eventcalendar} from "@mobiscroll/react";
import moment from 'moment';
import {addSpeciality, deleteSpeciality, addDepartment, deleteDepartment, deleteOrganization} from '../../../services/user.service';
import {getCoach} from "../../../services/coach.service";
import NotUser from '../../../assets/img/notUser.png';


const EditCoach = () => {
  const location = useLocation();
  const [file, setFile] = useState(null);

  console.log('coach in editcoach', location.state.idCoach)

    let days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [image, setImage] = useState('');
    const [birthday, setBirthday] = useState('');
    const [gender, setGender] = useState('');
    const [countryId, setCountryId] = useState('');
    const [city, setCity] = useState(null);
    const [description, setDescription] = useState('');
    const [myEvents, setMyEvents] = useState([]);
    const [specialityIds, setSpecialityIds] = useState([]);
    const [companyIds, setCompanyIds] = useState([]);

    const [formData, setFormData] = useState({});
    const [departments, setDepartments] = useState([]);
    const [errors] = useState({});
    const [preview, setPreview] = useState(null);
    const [specialtyChips, setSpecialtyChips] = useState([]);
    const [companyChips, setCompanyChips] = useState([]);

    const [departmentChips, setDepartmentChips] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [cities, setCities] = useState([]);
    const formRef = useRef(); // Crear referencia al formulario


    useEffect(() => {
      getOneCoach();
    }, [])

    useEffect(() => {
      if (specialtyChips.length > 0) {
        let ids = []
        specialtyChips.forEach(speciality => {
          ids.push(speciality.speciality_id ? speciality.speciality_id : speciality.id)
        })
        setSpecialityIds(ids)
      }
    }, [specialtyChips])

    useEffect(() => {
      if (companyChips.length > 0) {
        let ids = []
        companyChips.forEach(company => {
          ids.push(company.organization_id ? company.organization_id : company.id)
        })
        setCompanyIds(ids)
      }
    }, [companyChips])

    useEffect(() => {
      console.log('this is a departmet chips', departmentChips)
    }, [departmentChips])


    const getOneCoach = () => {
      getCoach(location.state.idCoach).then(res => {
        console.log('get coach', res.data)
        setName(res.data.name);
        setLastname(res.data.lastname);
        setEmail(res.data.email);
        setImage(res.data.image);
        setPreview(ImagesUrl.base_url_img + res.data.image)
        setBirthday(res.data.birthday);
        setGender(res.data.gender);
        setCountryId(res.data.country_id);
        setCity(res.data.city)
        setDescription(res.data.description);
        setSpecialtyChips(res.data.Specialities);
        setCompanyChips(res.data.Organizations);
        setDepartmentChips(res.data.Departments)
        console.log('contrusij', res.data)
        if (res.data.country_id !== null && res.data.country_id !== undefined && res.data.country_id !== '') {
            getCityOptions(res.data.country_id)
        }
        console.log('coach recogido con exito', res.data);

        let events = [];
        res.data.WorkingHours.forEach(event => {
            console.log('EVENT', event);
            events.push(event.recurring);
        })
        setMyEvents(events);
    }).catch(err => {
        console.log('Error al recoger el coach',err);
    })
    }



  const closeModal = () => {
    setShowModal(false);
  };

  const handleCompanyChange = (val) => {
    console.log('val', val)
    setFormData({ ...formData, organization_id: val.id });
    const opcionEncontrada = companyChips.find((opcion) => opcion.id === val.id || opcion.organization_id === val.id);
    if(!opcionEncontrada){
      setCompanyChips([...companyChips, { ...val }]);
      fetchDepartments(val.id)
    } else {

      let organizations = companyChips
      let newOrganizations = organizations.filter(organization => (organization.hasOwnProperty('id') && organization.id !== val.id) || (organization.hasOwnProperty('organization_id') && organization.organization_id !== val.id))
      setCompanyChips([...newOrganizations])
      deleteOrganization(location.state.idCoach, val.id).then(res => {
      })
    }
   }

   const handleDepartmentChange = (val) => {
    console.log('val', val)
    setFormData({ ...formData, department_id: val.id });
    const opcionEncontrada = departmentChips.find((opcion) => opcion.id === val.id);
    if(opcionEncontrada == null){
      setDepartmentChips([...departmentChips, { ...val }]);
    }
   addDepartment(location.state.idCoach, val.id, val.name).then(res => {
       console.log('departamento añadido con exito', res.data);
   }).catch(err => {
       console.log('Error al añadir departamento', err);
   })
   }

  const handleSpecialtyChange = (val) => {
    console.log('val', val)
    const opcionEncontrada = specialtyChips.find((opcion) => opcion.id === val.id || opcion.speciality_id === val.id);
    console.log('option', opcionEncontrada)
    if(!opcionEncontrada){
     setSpecialtyChips([...specialtyChips, { ...val }]);
     addSpeciality(location.state.idCoach, val.id, val.name).then(res => {
          console.log('specialidad añadida', res.data);
      }).catch(err => {
          console.log('Error al añadir especialidad', err);
      })
    } else {
      let specialities = specialtyChips
      console.log('val', val.id)
      let newSpecialities = specialities.filter(speciality => (speciality.hasOwnProperty('id') && speciality.id !== val.id) || (speciality.hasOwnProperty('speciality_id') && speciality.speciality_id !== val.id))
      console.log('sepecialities', newSpecialities)
      setSpecialtyChips([...newSpecialities])
      deleteSpeciality(location.state.idCoach, val.id).then(res => {
        console.log('especialidad eleiminada con exito', res.data)
      }).catch(err => {
          console.log('Error al eliminar la especialidad', err);
      })
    }
      
  }

  const fetchDepartments = async (companyId) => {
    try {
        const company = await OrganizationService.getById(companyId)
        setDepartments(company.Departments)
      } catch (error) {
        console.error('Error al obtener los coaches:', error);
      }
  };

  const handleChipDeleteDepartment = (option) => {
    setDepartmentChips(departmentChips.filter((item) => item !== option));
    deleteDepartment(location.state.idCoach, option.id).then(res => {
        console.log('Departamento eliminado con exito', res.data);
    }).catch(err => {
        console.log('Error al eliminar el departamento', err);
    })
  };



  const handleFileChange = (e) => {
    const fileTarget = e.target.files[0];
     setFile(fileTarget)
    if (fileTarget) {
      console.log('enter with fileTarget')
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('new previwe', reader.result)
        setPreview(reader.result);
      };
      reader.readAsDataURL(fileTarget);
    } else {
      setPreview(null);
    }
  };

  const handleAvatarClick = () => {
    document.getElementById('photoInput').click();
  };


  const handleSubmit = async (e) => {
      e.preventDefault();
      let dataUpdate;
      if (password !== '' && password2 !== '' && password === password2) {
          dataUpdate = {
              name: name,
              lastname: lastname,
              email: email,
              password: password,
              image: image,
              birthday: birthday,
              gender: gender,
              country_id: countryId,
              city: city,
              description: description,
              //organizations: organizationId,
              //departments: departmentId,
              //user_type: userType
          }
      } else {
          dataUpdate = {
              name: name,
              lastname: lastname,
              email: email,
              image: image,
              birthday: birthday,
              gender: gender,
              country_id: countryId,
              city: city,
              description: description,
              //: organizationId,
              //departments: departmentId,
              //user_type: userType
          }
      }
      console.log('Procesar el envío del formulario:', dataUpdate);
      try {
        await UserService.update(location.state.idCoach, dataUpdate);
        if (password !== '' && password2 !== '' && password === password2) {
            setPassword('');
            setPassword2('');
        }
        await uploadImage()
        setShowModal(true);
      } catch (error) {
        console.error(error);
        alert('Error al actualizar', error);
      }
  };


  const uploadImage = async () => {
    console.log("uploadImage")
    if(file != null){
    try {
      const imageData = new FormData();
      imageData.append('file',file);
      imageData.append('user_id',location.state.idCoach)
      console.log(imageData)
      console.log('Procesar el envío del formulario:', imageData);
      await ImageService.upload(imageData);
    } catch (error) {
      console.error('Error al subir la imagen:', error);
      throw error;
    }
  }
  };

  const deleteWorkingHour = (id) => {
      CoachService.deleteHours({id: id}).then(res => {
        getOneCoach();
      }).catch(err => {
        console.log('No se ha podido eliminar el horario, intentalo más tarde.')
      })
  }


  const getCityOptions = (isoCountry) => {
      let headers = new Headers();
      headers.append("X-CSCAPI-KEY", "aUF2QTVOTDZxbG16Zjc4TFZEcTQxa3ZSWTc4YkJjYjlJeFZPdHNPdQ==\n");

      let requestOptions = {
          method: 'GET',
          headers: headers,
          redirect: 'follow'
      };

      fetch(`https://api.countrystatecity.in/v1/countries/${isoCountry}/cities`, requestOptions)
          .then(response => response.text())
          .then(result => setCities(JSON.parse(result)))
          .catch(error => console.log(error));
  }


  return (

    <div className='generalContainerEditCoach'>
      <TopNavBar text="Editar coach"></TopNavBar>
      <form ref={formRef} onSubmit={handleSubmit}>

      <div className='form-container'>

      <div className='formAddPhoto'>
           <form >
            <div  className='avatarStyle' >
                <input
                type="file"
                id="photoInput"
                name="image"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                />
                <AddAvatar src={image || file ? preview : NotUser} onClick={handleAvatarClick} />
            </div>
            </form>
            <div className='addStyle'>
            <AddButton text="Agregar foto" press={handleAvatarClick}  id="photoInput" ></AddButton>
            </div>
        </div>

      <div >
        <div className='form-field'>
        <InputText placeholder={'Nombre'}
        name="name"
        oneData={true}
        value={name} changeValue={value => setName(value)}> </InputText>
        {errors.name && <p>{errors.name}</p>}
       </div>

       <div className='form-field'>
        <InputText placeholder={'Apellido'}
        name="lastname"
       oneData={true}
        value={lastname} changeValue={value => setLastname(value)}> </InputText>
        {errors.lastname && <p>{errors.lastname}</p>}
       </div>

        <div className='form-field'>
        <InputText
        name="email"
        oneData={true}
        placeholder={'Email'}
          type="email"
        value={email} changeValue={value => setEmail(value)}> </InputText>
        {errors.email && <p>{errors.email}</p>}
        </div>
        <div className='form-field'>
        <InputText placeholder="Contraseña"
         name="password"
       oneData={true}
        type="password" changeValue={value => setPassword(value)} value={password}> </InputText>
        {errors.password && <p>{errors.password}</p>}
         </div>
         <div className='form-field'>
        <InputText  placeholder="Repetir contraseña"
         name="password2"
        oneData={true}
         type="password" changeValue={value => setPassword2(value)} value={password2}> </InputText>
        {errors.password && <p>{errors.password}</p>}
         </div>
         </div>
        </div>
    <div>
      <textarea className="descripcion-coach"
        id="description"
        name="description"
        maxLength={500}
        value={description}
        onChange={value => setDescription(value.target.value)}
        placeholder={'Descripción'}
        rows={5}
        cols={50}
      />
      <div>
        {/* <small>{formData.description.length}/500</small> */}
      </div>
    </div>


<div className='containerDropdownsEditCoach'>
<div className='form-fields-dropdowns'>
         <div className='form-field-dropdown'>
             {console.log('CUMPLEAÑOS', moment(formData.birthday).format('DD/mm/yyyy'))}
         <InputDateText placeholder="Fecha de nacimiento"
        oneData={true}
         name="birthday" changeValue={value => setBirthday(value)} value={moment(birthday).format('yyyy-MM-DD')}> </InputDateText>
         </div>
         <div className='form-field-dropdown'>
        <CountryDropdown
            value={countryId ? countryId : null}
            initialText={countryId ? countryId : 'Pais'}
            onChange={(val)=>{
                setCountryId(val)
                getCityOptions(val)
            }}
        >
        </CountryDropdown>
         </div>
         <div className='form-field-dropdown'>
             {console.log('city', city)}
         <GeneralDropdown
             value={city}
             options={cities}
             initialText={'Ciudad'}
             onChange={(val)=>{
                setCity(val)
             }}
         >
         </GeneralDropdown>
         </div>
         </div>

         <div className='form-fields-dropdowns'>
         <div className='form-field-dropdown'>
        <SpecialtiesDropdown initialText="Especialidades" onChange={handleSpecialtyChange} itemIds={specialityIds}></SpecialtiesDropdown>

         </div>
         <div className='form-field-dropdown'>
         <CompaniesDropdown initialText={'Empresa'} onChange={handleCompanyChange} itemIds={companyIds}></CompaniesDropdown>
         </div>
         <div className='form-field-dropdown'>
         <DepartmentDropdown options={departments} initialText={'Departamento'} onChange={handleDepartmentChange}></DepartmentDropdown>
         </div>
         </div>

  <div className="chips-row-container">
         <div className="chips-container">
          {/*<Select
            style={{width: '100%'}}
            multiple
          >
                <option></option>
            {
              specialtyChips.map(speciality => {
                return (
                  <option>{speciality.speciality_name}</option>
                )
              })

            }
          </Select>*/}
      </div>

      <div className="chips-container">
        {/*{companyChips.map((option) => (
          <div key={option.id} className="chip">
            <span>{option.organization_name}</span>
            <button onClick={() => handleChipDeleteCompany(option)}>&times;</button>
          </div>
        ))}*/}
      </div>


      <div className="chips-container">
        {departmentChips.map((option) => {
            return (
              <div key={option.id} className="chip">
              <span>{option.department_name}</span>
              <button onClick={() => handleChipDeleteDepartment(option)}>&times;</button>
            </div>
            )
      })}
      </div>
      </div>

         <div className='flexEnd'>
         <p className='textTimeTitle'>Horarios de atención</p>
         <div className='timeButton' >
         {/*<Button text="Agregar horarios" ></Button>*/}
         </div>
        </div>
            <Eventcalendar
                className={'calendar'}
                themeVariant="light"
                newEventText={'Work'}
                event
                dragToCreate={true}
                clickToCreate={false}
                dragToMove={false}
                colorEventList={true}
                day
                colors={['#0F1841']}
                onEventCreate={async e => {
                    console.log('THIS IS AN EVENT CREATED', e)
                    let dateStart = new Date(e.event.start);
                    let yearStart = dateStart.getFullYear();
                    let monthStart = ("0" + (dateStart.getMonth() + 1)).slice(-2);
                    let dayStart = ("0" + dateStart.getDate()).slice(-2);
                    let hoursStart = ("0" + dateStart.getHours()).slice(-2);
                    let minutesStart = ("0" + dateStart.getMinutes()).slice(-2);
                    let secondsStart = ("0" + dateStart.getSeconds()).slice(-2);
                    let dateStartFormated = `${yearStart}-${monthStart}-${dayStart}T${hoursStart}:${minutesStart}:${secondsStart}+02:00`;
                    console.log('DIA DE LA SEMANA', dateStartFormated)
                    let dateEnd = new Date(e.event.end);
                    let yearEnd = dateEnd.getFullYear();
                    let monthEnd = ("0" + (dateEnd.getMonth() + 1)).slice(-2);
                    let dayEnd = ("0" + dateEnd.getDate()).slice(-2);
                    let hoursEnd = ("0" + dateEnd.getHours()).slice(-2);
                    let minutesEnd = ("0" + dateEnd.getMinutes()).slice(-2);
                    let secondsEnd = ("0" + dateEnd.getSeconds()).slice(-2);
                    let dateEndFormated = `${yearEnd}-${monthEnd}-${dayEnd}T${hoursEnd}:${minutesEnd}:${secondsEnd}+02:00`;
                    await CoachService.addHours(
                        {
                            user_id: location.state.idCoach,
                            start: dateStartFormated,
                            end: dateEndFormated,
                            weekday: days[dateStart.getDay()]
                        }
                    );
                    getOneCoach();
                }}
                onEventUpdated={e => {
                    console.log('THIS IS AN EVENT UPDATES', e);
                    deleteWorkingHour(e.event.id)
                }}
                onEventDeleted={e => {
                    console.log('THIS IS AN EVENT DELETED', e)
                    deleteWorkingHour(e.event.id)
                }}
                invalid={[]}
                data={myEvents}
                view={{
                    schedule: {
                        type: 'week',
                        startDay: 1,
                        endDay: 0,
                        startTime: '00:00',
                        endTime: '23:59',
                    }
                }}
            />
            <div className='center'>
                <Button text="Guardar" type="submit" ></Button>
            </div>
         </div>

        <div className='margin-bottom'>

        </div>
    </form>
    <div>
    <Modal
        showModal={showModal}
        closeModal={closeModal}
        title="Correcto!"
        description="Se ha modificado el coach correctamente."
      />
</div>
    </div>
);};

export default EditCoach;
