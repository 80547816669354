import React, {useEffect, useState} from 'react';

import './coachStats.css';

import InputDateText from '../../components/inputDateText/inputDateText';
import Button from '../../components/button/button.component';
import CardStats from '../../components/cardStats/cardStats.component';
import ContainerGraphics from "../../components/containerGraphics/containerGraphics.component";
import GeneralDropdown from "../../components/generalDropdown/generalDropdown.component";

import {getCoachStats} from "../../services/stats.service";
import {message, Spin} from "antd";
import {IconAssets} from "../../utils/ImageAssets";
import {CoachService} from "../../services/mbc.service";

const CoachStats = () => {
    const [stats, setStats] = useState(null)
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [coaches, setCoaches] = useState([]);
    const [coachId, setCoachId] = useState([]);

    // Se ejectuta al entrar en la vista
    useEffect(() => {
        // LLamaa a getStats con los valores a vacios para que vengas las stats sin filtrar
        getStats('', '', '');
        getCoaches();
    }, [])

    // Recoge todos los coaches
    const getCoaches = async () => {
        try {
            const data = await CoachService.getAll()
            console.log('sadfsdf', data);
            setCoaches(data);
        } catch (error) {
            console.error('Error al obtener los coaches:', error);
        }
    }

    // Recoge las estadisticas de del coach filtrando por los datos (start, end y idCoach)
    const getStats = (start, end, idCoach) => {
        getCoachStats(start, end, idCoach).then(res => {
            console.log('Estadisticas del coach recogidas cone xito', res.data);
            setStats(res.data)
        }).catch(err => {
            console.log('ERROR al recoger las estadisticas del coach',err);
        })
    }

    // Descarga un excel de las estadisticas ya sean enteras o por los datos que se hayan filtrado
    const downloadExcel = (start, end, idCoach) => {
        getCoachStats(start, end, idCoach, ".xlsx").then(res => {
            
            console.log('Estadisticas excel', res.data);

            const blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              
              const url = window.URL.createObjectURL(blob);
              
              // Create an anchor element to trigger the download
              const downloadLink = document.createElement('a');
              downloadLink.href = url;
              downloadLink.download = 'estadisticas_coach_mbc.xlsx'; // Specify the desired filename
              downloadLink.click();
              
              // Don't forget to clean up the URL after the download
              window.URL.revokeObjectURL(url);

        }).catch(err => {
            console.log('ERROR al recoger las estadisticas generales', err);
        })
    }

    // Comprueba si los filtros cumplen los requisitos para poder buscar por los filtros
    const applyFilters = () => {
        // Los filtros deben cumplir si le pones fecha poner ambas fechas o tener un id de coach para buscar simplemente los datos de ese coach
        if ((startDate !== null && endDate !== null) || coachId !== null) {
            getStats(startDate, endDate, coachId);
        } else {
            message.error('Rellena los filtros.')
        }
    }

    return (
        <div className={'containerCoachStats'}>
            <p className={'titleCoachStats'}>Coach services</p>

             <div className={'containerFiltersCoachStats'}>
                <div className={'containerDateFiltersCoachStats'}>
                    <InputDateText changeValue={value => setStartDate(value)} oneData/>
                    <InputDateText changeValue={value => setEndDate(value)} oneData/>

                    <GeneralDropdown options={coaches} initialText={'Seleccione un coach'} onChange={value => setCoachId(value)} returnId/>
                </div>
                <div style={{width: 200}}>
                    <Button text={'Aplicar'} press={() => applyFilters()}/>
                </div>

                <div style={{width: 200}}>
                    <Button text={'Descargar datos'} press={() => downloadExcel(startDate,endDate,coachId)}/>
                </div>
            </div>

            {/*<div className={'containerSearcherCoachStats'}>
                <Searcher/>
            </div>*/}

            {stats ?
                <>
                    <div className={'containerCardsCoachStats'}>
                        <CardStats value={stats.coach_rating} text={'Valoración del coach'} icon={IconAssets.starStats}/>
                        <CardStats value={stats.status_rating} text={'Nivel de satisfación'} icon={IconAssets.starStats}/>
                        <CardStats value={stats.interactions} text={'Total de interacciones'} icon={IconAssets.responseStats}/>
                        <CardStats value={stats.average_session_duration + ' min'} text={'Duración media sesión'} icon={IconAssets.timelaspseStats}/>
                        <CardStats value={stats.average_videocall_duration + ' min'} text={'Duración media videocall'} icon={IconAssets.timelaspseStats}/>
                        <CardStats value={stats.response_time + ' min'} text={'Tiempo medio respuesta'} icon={IconAssets.timelaspseStats}/>
                        <CardStats value={stats.interactions_by_hour} text={'Nº de intercciones por hora'} icon={IconAssets.messageStats}/>
                        <CardStats value={stats.videocalls_by_hour} text={'Nº de videocall por hora'} icon={IconAssets.videoStats}/>
                        <CardStats value={stats.users_by_hour} text={'Usuarios atendidos por hora'} icon={IconAssets.profileStats}/>
                        <CardStats value={stats.worked_hours} text={'Horas trabajadas'} icon={IconAssets.timelaspseStats}/>
                    </div>

                    <ContainerGraphics title={'Tipología de sesión'} data={stats.specialities_graph} type={'donut'}/>
                </>
                :
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 30}}>
                    <Spin tip={'Cargando'} size={'large'}/>
                </div>
            }

        </div>
    )

}

export default CoachStats;
