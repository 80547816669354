import React, {useEffect, useState} from 'react';
import './countryDropdown.css';
import {getCountries} from "../../services/user.service";

/*const countries = [
  { id: 1, name: 'España' },
  { id: 2, name: 'United States' },
  { id: 3, name: 'Canada' },
  { id: 4, name: 'Mexico' },
  // Agrega más países aquí si lo deseas
];*/


const CountryDropdown = ({value, initialText, onChange }) => {
  const [countries, setCountries] = useState(null);

  // Se ejecuta al entrar a la vista
  useEffect(() => {
    // Recoge todos lo paises
    getCountries().then(res => {
      setCountries(res.data)
      console.log('Paises recogidos con exito', res.data);
    }).catch(err => {
      console.log('ERROR al recoger los paises', err);
    })
  }, [])

  // Maneja el componnete cuando se cambia la opcion
  const handleOptionChange = (event) => {
    const countryId = parseInt(event.target.value);
    countries.find((c) => c.iso === countryId);
    // Envia a la vista el valor que se a seleccionado en el dropdown
    onChange(event.target.value)
  };

  return (
      countries !== null &&
    <div >
      <select className='containerStyle' id="dropdown-select" onChange={handleOptionChange}>
        {!value && <option value="">{initialText}</option>}
        {countries.map((country) => (
          <option key={country.id} value={country.iso} selected={value === country.iso}>
            {country.name_es}
          </option>
        ))}
      </select>
    </div>
  );
};


export default CountryDropdown;
