import React from 'react';

import './dotQuantity.css';

// Muestra el componente de "nuevas acciones no vistas y la cantidad de cosas por ver"
const DotQuantity = ({style, size, focus}) => {

    return (
        <div style={style} className={[size === 'little' && (focus) ? 'containerDotQuantityLittleFocus' : 'containerDotQuantityLittleNoFocus']}>
            8
        </div>
    )
}

export default DotQuantity;
