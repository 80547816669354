import create from 'zustand'

// Variable global con la que se ve a un router u otro segun su valor
// Su valor se detecta cuando entras a la aplicacion comprobando si tienes token o no
const loginStore = create((set, get) => ({
    login: false,
    setLogin: login => set(state => ({login: login})),
}));

export default loginStore;
