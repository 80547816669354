import React, { useState, useRef, useEffect } from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

import './editSection.css';

import InputText from '../../../components/inputText/inputText.component';
import Button from '../../../components/button/button.component';

import TopNavBar from '../../../components/topNavBar/topNavBar.component';
import {getCategoryPosts, editPostCategory} from '../../../services/posts.service';
import {message} from 'antd';

const EditSection = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const initialState =
        {
            name: '',
            description: '',
        }
    const [formData, setFormData] = useState(initialState);
    const formRef = useRef();

    // Al escribir en el input guarda en el objeto el nombre de la propiedad y su valor para poder enviarlo al server
    const handleChange = (name, value) => {
        setName(value);
        setFormData({ ...formData, [name]: value });
    };

    // Se ejecuta al entrar a ala vista
    useEffect(() => {
        // Recoge una categoria de posts por id para editarla
        getCategoryPosts(location.state.idArticle.id).then(res => {
            setName(res.data.name);
            setDescription(res.data.description);
            console.log('Categoria de post recogida', res.data);
        }).catch(err => {
            console.log('Error al recoger la categoria de post', err);
        })
    }, [])

    const handleInputChange = (event) => {
        if (event.target.value.length <= 500) {
            setDescription(event.target.value);
            setFormData({ ...formData, description: event.target.value });
            formData.description = event.target.value
        }
    };

    // Envia los valores modificados de la categoria para editarla en el servidor
    const handleSubmit = async (e) => {
        e.preventDefault();
        editPostCategory(location.state.idArticle.id, name, description).then(res => {
            console.log('Categoria de post editada', res.data);
            message.success('Articulo editado con exito.')
            navigate('/articles');
        }).catch(err => {
            console.log('Error al editar la categoria', err);
        })
    };

    return (
        <div className={'containerAddArticles'}>
            <TopNavBar text="Agregar articulo"></TopNavBar>
            <div className={'containerBody'}>
                <p className='titleArticles'> Agregar Articulo</p>
                <form ref={formRef} onSubmit={handleSubmit}>
                    <h3 className={'left-align'}>Nombre</h3>
                    <div className='form-field'>
                        <InputText placeholder="Nombre"
                                   name="name"
                                   value={name} changeValue={handleChange}> </InputText>
                    </div>
                    <h3 className={'left-align'}>Descripción</h3>
                    <div>
            <textarea className="description-articles"
                      id="description"
                      name="description"
                      maxLength={500}
                      value={description}
                      onChange={handleInputChange}
                      placeholder="Escribe una descripción del articulo.."
                      rows={5}
                      cols={50}
            />
                        <div>
                            <small>{formData.description.length}/500</small>
                        </div>
                    </div>
                    <div className='center'>
                        <Button text="Guardar" type="submit" ></Button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default EditSection;
