import React, { useState, useEffect } from 'react';
import { SpecialtiesService } from '../../services/mbc.service';

// Muestra el componente de dropdown de las especialidades
const SpecialtiesDropdown = ({initialText, onChange, itemIds = [] }) => {
  const [selectedOption, setSelectedOption] = useState(initialText);
  const [specialities, setSpecialties] = useState([]);

  // Maneja el componente cuando se cambia la opcion
  const handleOptionChange = (event) => {
    const idSeleccionado = parseInt(event.target.value, 10);
    const opcionEncontrada = specialities.find((opcion) => opcion.id === idSeleccionado);
    setSelectedOption(opcionEncontrada.id);
    // Envia a la vista la opcion seleccionada
    onChange(opcionEncontrada)
  };

  // Al renderizar el componente
  useEffect(() => {
    const fetchSpecialties = async () => {
      try {
        // Trae todas las especialidades
        const data = await SpecialtiesService.getAll()
        setSpecialties(data);
      } catch (error) {
        console.error('Error al obtener los coaches:', error);
      }
    };
    fetchSpecialties();
  }, []);

  return (
    <div >
      <select className='containerStyle' id="dropdown-select" onChange={handleOptionChange} value={selectedOption}>
        <option value="">{initialText}</option>
        {specialities.map((option) => (
          <option 
          key={option.id} 
          style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', minWidth: 300}}
          value={option.id}
          >
            {itemIds.includes(option.id) && <span style={{marginRight: 10}}>X</span>}
            <span>{'   '+option.name}</span>
          </option>
        ))}
      </select>
    </div>
  );
};

export default SpecialtiesDropdown;
