import React, {useEffect, useState} from 'react';

import './navBar.css';
import { ImageAssets } from '../../utils/ImageAssets';

import {useLocation} from 'react-router-dom';

import UserNavBar from '../userNavBar/userNavBar.component';
import Menu from '../menu/menu.component';
import {getProfile} from "../../services/user.service";

// Muestra el componente del navbar
const NavBar = () => {
    const location = useLocation();
    const [user, setUser] = useState(null)

    // Se ejecuta al renderizar el componente
    useEffect(() => {
        getUserProfile();
    }, [])

    // Recoge el perfil del usuario
    const getUserProfile = () => {
        getProfile().then(user => {
            console.log('Perfil recogido con exito', user.data);
            setUser(user.data);
        }).catch(err => {
            console.log('ERROR al recoger el perfil', err);
        })
    }

    return (
        <div className={'containerNavBar'}>
            <img className={'logoNavBar'} alt={''} src={ImageAssets.originalLogo}/>


            <div className={'containerItemsNavBar'}>
                {/* Muestra el componente que se muestare en el navbar con los datos del admin logueado */}
                <UserNavBar
                    image={user && user.image}
                    fullName={user && user.name + ' ' + user.lastname}
                    email={user && user.email}
                />

                {/* Muestra el componente del menu */}
                <Menu route={location.pathname}/>
            </div>

        </div>
    )
}

export default NavBar;
