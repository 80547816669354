import axios from 'axios';

const axiosInstance = axios.create({baseURL: 'https://node.mybeatcoach.com/api/', responseType: 'json'});

export const getHolidays = () => {
    const headers = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return new Promise((resolve, reject) => {
        axiosInstance.get('holidays', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export const deleteHolidays = (id) => {
    const headers = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };
    
    return new Promise((resolve, reject) => {
        axiosInstance.post(`holidays/${id}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export const createHoliday = (date) => {
    const headers = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    const data = {
        date: date
    }
    
    return new Promise((resolve, reject) => {
        axiosInstance.post(`holidays`, data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}