import React from 'react';
import './avatar.css';


const AddAvatar = ({ src, onClick }) => (
    <img
    className='avatarStyle'
      src={src}
      alt="Avatar"
      onClick={onClick}
    />
  );


export default AddAvatar;
