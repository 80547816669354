import React, {useEffect, useState} from 'react';

import './menu.css';
import {IconAssets} from '../../utils/ImageAssets';

import {useNavigate} from 'react-router-dom';

import CloseSesion from '../closeSesion/closeSesion.component';

// Muestar el componente del menu que se muestar en el navbar
const Menu = ({route}) => {
    const navigate = useNavigate();
    const [userType, setUserType] = useState(null);

    // Al renderizar el componenete
    useEffect(() => {
        // Recoge el usuario de localstorage
        let user = localStorage.getItem('USER');
        // Guarda el tipo de usuario para mostrar unas opciones u otras
        setUserType(JSON.parse(user).user_type)
    }, [])

    // Muestra las opciones del menu de una manera u otra segun la ruta en la que esta actualmente
    return (

        <div className={'containerMenu'}>
         <div className='menuOptions'>

             {userType !== 'admin' &&
                 <div className={(route === '/' || route === '/coaches') ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => navigate('/coaches')}>
                     <img className={'iconMenuItem'} alt={''} src={(route === '/' || route === '/coaches') ? IconAssets.coachesFocus : IconAssets.coachesNoFocus}/>
                     <p className={route === '/' || route === '/coaches' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Coaches</p>
                 </div>
             }

             {userType !== 'admin' &&
                 <div className={route === '/companies' ? 'MenuItem' : 'MenuItemNoFocus'}  onClick={() => navigate('/companies')}>
                     <img className={'iconMenuItem'} alt={''} src={route === '/companies' ? IconAssets.empresasFocus : IconAssets.empresasNoFocus}/>
                     <p className={route === '/companies' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Empresas</p>
                 </div>
             }

             {userType !== 'admin' &&
                 <div className={route === '/schedules' ? 'MenuItem' : 'MenuItemNoFocus'}  onClick={() => navigate('/schedules')} >
                     <img className={'iconMenuItem'} alt={''} src={route === '/schedules' ? IconAssets.agendaFocus : IconAssets.agendaNoFocus}/>
                     <p className={route === '/schedules' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Agenda</p>
                 </div>
             }

             {userType !== 'admin' ?
                 <div className={route === '/users' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => navigate('/users')}>
                     <img className={'iconMenuItem'} alt={''} src={route === '/users' ? IconAssets.usuariosFocus : IconAssets.usuariosNoFocus}/>
                     <p className={route === '/users' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Usuarios</p>
                 </div>
                 :
                 <div className={route === '/' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => navigate('/')}>
                     <img className={'iconMenuItem'} alt={''} src={route === '/' ? IconAssets.usuariosFocus : IconAssets.usuariosNoFocus}/>
                     <p className={route === '/' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Usuarios</p>
                 </div>
             }

             {userType !== 'admin' &&
                 <div className={route === '/rates' ? 'MenuItem' : 'MenuItemNoFocus'}  onClick={() => navigate('/rates')} >
                     {route === '/rates' ? <i className="fa fa-check iconMenuItem" style={{fontSize: 23, color: '#FFFFFF'}}></i> : <i className="fa fa-check iconMenuItem" style={{fontSize: 23, color: '#8491A5'}}></i>}
                     <p className={route === '/rates' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Valoraciones</p>
                 </div>
             }

             {userType !== 'admin' &&
                 <div className={route === '/specialties' ? 'MenuItem' : 'MenuItemNoFocus'}  onClick={() => navigate('/specialties')}>
                     <img className={'iconMenuItem'} alt={''} src={route === '/specialties' ? IconAssets.especialidadesFocus : IconAssets.especialidadesNoFocus}/>
                     <p className={route === '/specialties' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Especialidades</p>
                 </div>
             }

             {userType !== 'admin' &&
                 <div className={route === '/articles' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => navigate('/articles')}>
                     <img className={'iconMenuItem'} alt={''} src={route === '/articles' ? IconAssets.articlesFocus : IconAssets.articlesNoFocus}/>
                     <p className={route === '/articles' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Artículos</p>
                     {/*<DotQuantity style={{marginRight: 13}} size={'little'} focus={route === '/articles'}/>*/}
                 </div>
             }

             {userType !== 'admin' &&
                 <div className={route === '/bans' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => navigate('/bans')}>
                     {/*<img className={'iconMenuItem'} src={route === '/articles' ? IconAssets.articlesFocus : IconAssets.articlesNoFocus}/>*/}
                     {route === '/bans' ? <i className="fa fa-ban iconMenuItem" style={{fontSize: 27, color: '#FFFFFF'}}></i> : <i className="fa fa-ban iconMenuItem" style={{fontSize: 27, color: '#8491A5'}}></i>}
                     <p className={route === '/bans' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Baneos</p>
                     {/*<DotQuantity style={{marginRight: 13}} size={'little'} focus={route === '/articles'}/>*/}
                 </div>
             }

             {userType !== 'admin' &&
                 <div className={route === '/holidays' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => navigate('/holidays')}>
                     {/*<img className={'iconMenuItem'} src={route === '/articles' ? IconAssets.articlesFocus : IconAssets.articlesNoFocus}/>*/}
                     {route === '/holidays' ? <i className="fa fa-car iconMenuItem" style={{fontSize: 23, color: '#FFFFFF'}}></i> : <i className="fa fa-car iconMenuItem" style={{fontSize: 23, color: '#8491A5'}}></i>}
                     <p className={route === '/holidays' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Festivos</p>
                     {/*<DotQuantity style={{marginRight: 13}} size={'little'} focus={route === '/articles'}/>*/}
                 </div>
             }

            {userType !== 'admin' &&
                 <div className={route === '/addNotification' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => navigate('/addNotification')}>
                     {/*<img className={'iconMenuItem'} src={route === '/articles' ? IconAssets.articlesFocus : IconAssets.articlesNoFocus}/>*/}
                     {route === '/addNotification' ? <i className="fa fa-bell iconMenuItem" style={{fontSize: 23, color: '#FFFFFF'}}></i> : <i className="fa fa-bell iconMenuItem" style={{fontSize: 23, color: '#8491A5'}}></i>}
                     <p className={route === '/addNotification' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Notificaciones</p>
                     {/*<DotQuantity style={{marginRight: 13}} size={'little'} focus={route === '/articles'}/>*/}
                 </div>
             }

            <div className='optionsSeparator'>
                <img className={'iconMenuItem'} alt={''} src={IconAssets.statistics}/>
                <h3 className="textSeparator" >Estadísticas</h3>
            </div>

             {userType !== 'admin' &&
                 <div className={route === '/generalStats' ? 'MenuItemStats' : 'MenuItemNoFocusStats'} onClick={() => navigate('/generalStats')}>
                     {/* <img className={'iconMenuItem'} src={route === '/users' ? IconAssets.usuariosFocus : IconAssets.usuariosNoFocus}/> */}
                     <p className={route === '/generalStats' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>General</p>
                 </div>
             }

             {userType !== 'admin' &&
                 <div className={route === '/coachStats' ? 'MenuItemStats' : 'MenuItemNoFocusStats'} onClick={() => navigate('/coachStats')}>
                     {/* <img className={'iconMenuItem'} src={route === '/specialties' ? IconAssets.especialidadesFocus : IconAssets.especialidadesNoFocus}/> */}
                     <p className={route === '/coachStats' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Servicio de coach</p>
                 </div>
             }

            <div className={route === '/companiesStats' ? 'MenuItemStats' : 'MenuItemNoFocusStats'} onClick={() => navigate('/companiesStats')}>
                {/* <img className={'iconMenuItem'} src={route === '/chat' ? IconAssets.messagesFocus : IconAssets.messagesNoFocus}/> */}
                <p className={route === '/companiesStats' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Servicio de empresas</p>
            </div>
            </div>

            <CloseSesion/>

            </div>

    )
}

export default Menu;
