import React, {useState} from 'react';
import './notifications.css'
import {useNavigate} from 'react-router-dom'

import { Table} from 'antd';
import AddButton from  '../../components/addButton/addButton.component';

const Notifications = () => {
    const [notifications] = useState([{title: 'shjhfjhajsda', description: 'kjasdkjfhajsdhfkhfkahksdfh'}]);

    const navigate = useNavigate();

    // Inicializa la contante con las columnas que va a tener la tabla
  const columns = [
    {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: ' ',
      key: 'action',
      render: (_, record) => (
        <button
        className="hamburger-menu"
        onClick={(event) =>{handleContextMenu(event, record)}}
        >
      &#x2630;
    </button>
      ),
    },
  ];

  const onChange = () => {

  }

  const handleContextMenu = () => {

  }

    return (
        <div className='viewNotificationsContainer'>
        <div className='notificationsAddButton'>
            <AddButton text={"Agregar Notificación"}  press={() => navigate('/addNotification')}></AddButton>
        </div>

        <div className={'notificationsTableStyle'}>
            <Table className="custom-table"  columns={columns} dataSource={notifications}  onChange={onChange} scroll={false} />
        </div>
        {/*
        <div className={'specialtyTableStyle'}>
        <Table className="custom-table"  columns={columns} dataSource={specialties}  onChange={onChange} scroll={false} />
        </div>
        {contextMenuData.visible && (
          <div
            className="context-menu"
            style={{ top: contextMenuData.y, left: contextMenuData.x }}>
            <button onClick={handleEdit}>Editar</button>
            <button onClick={handleDelete}>Borrar</button>
          </div>
        )}*/}
      </div>
    )
}

export default Notifications;