import React, { useState, useEffect } from 'react';
import Searcher from '../../components/searcher/searcher.component';
import './users.css';
import {useNavigate} from 'react-router-dom';
import { UserService } from '../../services/mbc.service';
import { Table} from 'antd';
import { Avatar } from '@mui/material';
import AddButton from '../../components/addButton/addButton.component';
import Star from '../../assets/img/star.png'


const Users = () => {
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [userType, setUserType] = useState(null);
  const [contextMenuData, setContextMenuData] = useState({ visible: false, x: 0, y: 0, user: null });

  useEffect(() => {
    let user = localStorage.getItem('USER');
    setUserType(JSON.parse(user).user_type)
  }, [])

  const fetchUsers = async () => {
    try {
     const data = await UserService.getAll()
      console.log(data);
      setUsers(data);
      setAllUsers(data);
    } catch (error) {
      console.error('Error al obtener los coaches:', error);
    }
  };

  const searchUsers = (inputValue) => {
    let usersSearched = [];
    if (inputValue !== '' && inputValue !== null) {
      allUsers.forEach(user => {
        console.log('this is a user', user);
        let fullName = user.name.toUpperCase() + ' ' + user.lastname.toUpperCase();
        let typeUser = user.user_type.toUpperCase();
        if (fullName.normalize('NFD').replace(/\p{Diacritic}/gu, '').includes(inputValue.normalize('NFD').replace(/\p{Diacritic}/gu, '').toUpperCase()) || String(user.id) === inputValue ||  typeUser.includes(inputValue.toUpperCase()) || user.email.toUpperCase().includes(inputValue.toUpperCase())) {
          usersSearched.push(user);
        }
      })
      setUsers(usersSearched);
    } else {
      setUsers(allUsers);
    }
  }

  const handleClickOutside = (event) => {
    if (contextMenuData.visible) {
      handleMenuClose();
    }
  };

  const handleMenuClose = () => {
    setContextMenuData({ visible: false, x: 0, y: 0, user: null });
  };

  useEffect(() => {
    fetchUsers();
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [contextMenuData.visible]);

  const handleEdit = () => {
    console.log('Edit user:', contextMenuData.user);
    const user = contextMenuData.user;
    navigate('/editUser', { state: {idUser: user.id} })
    handleMenuClose();
  };

  const handleDelete = async () => {
    console.log('Delete user:', contextMenuData.user);
    try{
      const response = await UserService.delete(contextMenuData.user.id);
      console.log(response)
      fetchUsers();
      handleMenuClose();
    }catch(e){
        console.log("Error",e)
    }
  };

  const columns = [
    {
      title: 'Id empleado',
      key: 'employe_id',
      dataIndex: 'employe_id',
    },
    {
      title: 'Nombre',
      dataIndex: ['name', 'lastname'],
      key: 'name',
      render: (text, record) => <l style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{record.user_type === 'admin' && <img style={{width: 20, height: 20, marginRight: 5}} alt={''} src={Star}/>} {record.name}  {record.lastname} </l>,
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend'],
    },
    {
      title: 'Foto',
      dataIndex: 'image',
      key: 'image',
      render: (text, record) =>  <Avatar src={record.image}/>
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Empresa',
      key: 'organization_id',
      dataIndex: 'organization_id',
      render: (text, record) =>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <p style={{margin: 3}}>{record.Organizations.length > 0 ? record.Organizations[0].name : ''}</p>
            <p style={{margin: 3, color: '#CECECE'}}>{record.Departments.length > 0 ? record.Departments[0].name : ''}</p>
          </div>
    },
    {
      title: 'Tipo',
      key: 'user_type',
      dataIndex: 'user_type',
    },
    {
      title: ' ',
      key: 'action',
      render: (_, record) => (
          userType !== 'admin' &&
            <button
                className="hamburger-menu"
                onClick={(event) =>{handleContextMenu(event, record)}}
            >
              &#x2630;
            </button>

      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const handleContextMenu = (event, user) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenuData({ visible: true, x: event.clientX, y: event.clientY, user });
  };

  return (
    <div className='viewUserContainer'>
       <div className={'searchBarStyle'}>
       <Searcher changeValue={value => searchUsers(value)}/>
      </div>
      {userType !== 'admin' &&
        <div className='userAddButton'>
          <AddButton
              text={"Agregar Usuario"}
              press={() => navigate('/addUser')}
          >
          </AddButton>
        </div>
      }

      <div className={'userTableStyle'}>
      <Table style={{marginTop: userType !== 'admin' ? 0 : 20}} className="custom-table"  columns={columns} dataSource={users}  onChange={onChange} scroll={false} />
      </div>
      {(contextMenuData.visible && userType !== 'admin') && (
        <div
          className="context-menu"
          style={{ top: contextMenuData.y, left: contextMenuData.x }}>
          <button onClick={handleEdit}>Editar</button>
          <button onClick={handleDelete}>Borrar</button>
        </div>
      )}
    </div>
  );
};

export default Users;
