// src/components/AdminDashboard.js

import React, { useState, useEffect } from 'react';
import Searcher from '../../components/searcher/searcher.component';
import './coaches.css';
import {useNavigate} from 'react-router-dom';
import { CoachService } from '../../services/mbc.service';
import { ImagesUrl } from '../../utils/ImageAssets';
import { Avatar } from '@mui/material';
import { Table} from 'antd';
import AddButton from '../../components/addButton/addButton.component';
import {message} from 'antd';
import { deleteCoach } from '../../services/coach.service';

const Coaches = () => {
  const navigate = useNavigate();
  const [coaches, setCoaches] = useState([]);
  const [allCoaches, setAllCoaches] = useState([]);
  const [contextMenuData, setContextMenuData] = useState({ visible: false, x: 0, y: 0, user: null });

  // Recoge todos los coaches
  const fetchCoaches = async () => {
    console.log('fetchCoaches');
    try {
      const data = await CoachService.getAll()
      console.log(data);
      setCoaches(data);
      setAllCoaches(data)
    } catch (error) {
      console.error('Error al obtener los coaches:', error);
    }
  };

  // Abre/Cierra un pequeño menu con las opciones de editar y eliminar al clicar en el icono de hamburguesa
  const handleClickOutside = (event) => {
    if (contextMenuData.visible) {
      handleMenuClose();
    }
  };

  const handleMenuClose = () => {
    setContextMenuData({ visible: false, x: 0, y: 0, user: null });
  };

  // Se ejecuta al entrar en la vista y cada vez que cambia contextMenuData.visible
  useEffect(() => {
    fetchCoaches();
    // Detecta el click en la hamburguesa para gestionar la apertura y cierre del pequeño menu
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [contextMenuData.visible]);

  const searchCoaches = (inputValue) => {
      let coachSearched = [];
      if (inputValue !== '' && inputValue !== null) {
          allCoaches.forEach(coach => {
              let fullName = coach.name.toUpperCase() + ' ' + coach.lastname.toUpperCase();
              if (fullName.normalize('NFD').replace(/\p{Diacritic}/gu, '').includes(inputValue.normalize('NFD').replace(/\p{Diacritic}/gu, '').toUpperCase()) || coach.email.toUpperCase().includes(inputValue.toUpperCase())) {
                  coachSearched.push(coach);
              }
          })
          setCoaches(coachSearched);
      } else {
          setCoaches(allCoaches);
      }
  }

  // Al darle a la opcion de editar navega a la pagina para editar el coach
  const handleEdit = () => {
    const coach = contextMenuData.coach;
    navigate('/editCoach', { state: {idCoach: coach.id} })
    handleMenuClose();
  };

  // Al darle a la opcion de eliminar elimina el coach
  const handleDelete = async () => {
    deleteCoach(contextMenuData.coach.id).then(res => {
      console.log('Coach eliminado con exito', res.data);
      // Muestra el mensaje de coach eliminado con exito
      message.success('Coach eliminado con exito.');
      fetchCoaches();
    }).catch(err => {
      console.log('Error al eliminar el coach', err);
    })
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  // Maneja los cambios del pequeño menu de editar y eliminar
  const handleContextMenu = (event, coach) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenuData({ visible: true, x: event.clientX, y: event.clientY, coach });
  };

  // Inicializa la constante de las columanas que va a tener la tabla
  const columns = [
    {
      title: 'Nombre',
      dataIndex: ['name', 'lastname'], // an
      key: 'name',
      render: (text, record) => <l> {record.name}  {record.lastname} </l>,
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend'],
    },
    {
      title: 'Foto',
      dataIndex: 'image',
      key: 'image',
      render: (text, record) =>  <Avatar src={ ImagesUrl.base_url_img + record.image}/>
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Valoracion',
      key: 'rating',
      dataIndex: 'rating',
        render: (text, record) => <p style={{textAlign: 'center'}}>{record.rating.toFixed(0)}</p>
    },
    {
      title: 'T/Rta',
      key: 'response_time',
      dataIndex: 'response_time',
        render: (text, record) =>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{margin: 3}}>{record.response_time}</p>
                <p style={{margin: 2}}>seg</p>
            </div>
    },
    {
      title: 'Estado',
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: ' ',
      key: 'action',
      render: (_, record) => (
        <button
        className="hamburger-menu"
        onClick={(event) =>{handleContextMenu(event, record)}}
        >
     &#x2630;
    </button>
      ),
    },
  ];

  


  return (
  <div className={'viewCoachContainer'}>
 <div className={'searchBarStyle'}>
      <Searcher changeValue={value => searchCoaches(value)}/>
      </div>
      <div className='coachAddButton'>
      <AddButton text={"Agregar Coach"}  press={() => navigate('/addCoach')}></AddButton>
      </div>
      <div className={'coachTableStyle'}>
      <Table className={'custom-table'} pagination={{ pageSize: 20 }} columns={columns} dataSource={coaches} onChange={onChange}  scroll={false}/>
      </div>
      {contextMenuData.visible && (
        <div
          className="context-menu"
          style={{ top: contextMenuData.y, left: contextMenuData.x }}>
          <button onClick={handleEdit}>Editar</button>
          <button onClick={handleDelete}>Borrar</button>
        </div>
      )}
    </div>
  );
};

export default Coaches;
