import React, {useEffect, useState} from 'react';
import {message, Spin} from 'antd';

import './generalStats.css';

import InputDateText from '../../components/inputDateText/inputDateText';
import Button from '../../components/button/button.component';
import CardStats from '../../components/cardStats/cardStats.component';
import ContainerGraphics from "../../components/containerGraphics/containerGraphics.component";

import {getGeneralStats} from "../../services/stats.service";
import {IconAssets} from "../../utils/ImageAssets";

const GeneralStats = () => {
    const [stats, setStats] = useState(null)
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    // Se ejecuta al entrar a la vista
    useEffect(() => {
        getStats('', '')
    }, [])

    // Recoge las estadisticas segun los parametros enviados
    const getStats = (start, end) => {
        getGeneralStats(start, end).then(res => {
            console.log('Estadisticas generales recogidas con exito', res.data);
            setStats(res.data)
        }).catch(err => {
            console.log('ERROR al recoger las estadisticas generales', err);
        })
    }

    // Descarga un documento con los datos de las estadisticas segun parametros enviados
    const downloadExcel = (start, end) => {
        getGeneralStats(start, end, ".xlsx").then(res => {
            
            console.log('Estadisticas generales excel', res.data);

            const blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              
              const url = window.URL.createObjectURL(blob);
              
              // Create an anchor element to trigger the download
              const downloadLink = document.createElement('a');
              downloadLink.href = url;
              downloadLink.download = 'estadisticas_mbc.xlsx'; // Specify the desired filename
              downloadLink.click();
              
              // Don't forget to clean up the URL after the download
              window.URL.revokeObjectURL(url);

        }).catch(err => {
            console.log('ERROR al recoger las estadisticas generales', err);
        })
    }

    // Aplica los filtros para recoger estadisticas
    const applyFilters = () => {
        if (startDate !== null && endDate !== null) {
            getStats(startDate, endDate);
        } else {
            message.error('Rellena los filtros.')
        }
    }

    return (
        <div className={'containerGeneralStats'}>
            <p className={'titleGeneralStats'}>General</p>

            <div className={'containerFiltersGeneralStats'}>
                <div className={'containerDateFiltersGeneralStats'}>
                    <InputDateText changeValue={value => setStartDate(value)} oneData/>
                    <InputDateText changeValue={value => setEndDate(value)} oneData/>
                    <div style={{width: 200}}>
                        <Button text={'Aplicar'} press={() => applyFilters()}/>
                    </div>

                </div>
                <div style={{width: 200}}>
                    <Button text={'Descargar datos'} press={() => downloadExcel(startDate,endDate)}/>
                </div>
            </div>

            {stats ?
            <>
                <div className={'containerCardsGeneralStats'}>
                    <CardStats value={stats.total_organizations} text={'Total Empresas'} icon={IconAssets.companiesStats}/>
                    <CardStats value={stats.total_employees} text={'Total Empleados'} icon={IconAssets.groupStats}/>
                    <CardStats value={stats.total_logins} text={'Total Logins'} icon={IconAssets.lockStats}/>
                    <CardStats value={stats.total_active_users} text={'Total Usuarios con una interaccion'} icon={IconAssets.profileStats}/>

                    <CardStats value={stats.reponse_time + ' min'} text={'Tiempo medio respuesta'} icon={IconAssets.increaseStats}/>
                    <CardStats value={stats.total_sessions} text={'Total Sesiones terminadas'} icon={IconAssets.messageStats}/>
                    <CardStats value={stats.rating} text={'Valoracion de los coaches'} icon={IconAssets.starStats}/>
                    <CardStats value={stats.status_rating} text={'Nivel de satisfación'} icon={IconAssets.starStats}/>
                </div>

                <ContainerGraphics title={'Tipología de sesión'} data={stats.specialities_graph} type={'donut'}/>
            </>
            :
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 30}}>
                <Spin tip={'Cargando'} size={'large'}/>
            </div>
            }
        </div>

    )

}

export default GeneralStats;
