import React, { useState, useEffect } from 'react';
import './articles.css';
import {useNavigate} from 'react-router-dom';
import AddButton from '../../components/addButton/addButton.component';
import ArticleComponent from '../../components/article/article.component';
import { PostService } from '../../services/mbc.service';
import {deleteCategoryPosts, deletePost} from '../../services/posts.service';
import {message} from 'antd';
//import { name } from 'ejs';

const Articles = () => {
    const navigate = useNavigate();
    const [sections, setSections] = useState([]);

    // Se ejecuta al entrar en la vista
    useEffect(() => {
        fetchSectionArticles();
    }, []);

    // Recoge todas las categorias de articulos con sus respectivos articulos
    const fetchSectionArticles = async () => {
        try {
            const data = await PostService.getAll()
            setSections(data);
            console.log('POSTS ENCONTRADOS', data);
        } catch (error) {
            console.error('Error al obtener los coaches:', error);
        }
    };

    // Elimina una categoria de post
    const removeCategoryPosts = (event, idCategory) => {
        event.stopPropagation();
        // Pregunta si estas seguro de eliminar la categoria avisando que los articulos de esta categoria tambien se eliminaran
        if (window.confirm('¿Esta seguro de eliminar la categoria de articulo? Todos los articulos se eliminaran al eliminar la categoria.')) {
            // LLama al servidor para eliminar la categoria de post por id de categoria
            deleteCategoryPosts(idCategory).then(res => {
                console.log('Categoria de posts eliminada con exito', res.data);
                fetchSectionArticles();
                message.success('Categoria de posts eliminada con exito.')
            }).catch(err => {
                console.log('ERROR al eliminar el post', err);
            })
        }
    };

    // Elimina un articulo
    const removePost = (idPost) => {
        // Pregunta si estas seguro de aliminar este articulo
        if (window.confirm('¿Esta seguro de eliminar el articulo?')) {
            // LLama al servidor para eliminar el articulo por id
            deletePost(idPost).then(res => {
                console.log('Post eliminado con exito', res.data);
                fetchSectionArticles();
                message.success('Post eliminado con exito.')
            }).catch(err => {
                console.log('ERROR al eliminar el post', err);
            })
        }
    };

    return (
        <div className={'containerArticles'}>
            <p className='titleArticles'>Artículos</p>

            <p className={'descriptionArticles'}>Aquí encontrarás las ultimas actualizaciones acerca de las mejores practicas coaching asi como comunicaciones de importantes de la empresa</p>

            <div className='articleAddButton'>
                <AddButton text={"Agregar Seccion"} press={() => navigate('/addSection')}></AddButton>
            </div>

            <div className={'containerItemArticles'}>
                <div className={'articleComponent'}>
                    {sections.map((section, index) => (
                        <ArticleComponent section={section} idPostCategoryEdit={value => navigate('/editSection', {state: {idArticle: value}})} idPostCategoryRemove={value => removeCategoryPosts(value.event, value.id)} idPost={value => removePost(value)}></ArticleComponent>
                    ))}
                </div>
            </div>
        </div>

    )
}

export default Articles;
