import React from 'react';
import {useNavigate} from 'react-router-dom'

import './closeSesion.css';
import {IconAssets} from '../../utils/ImageAssets';

import {logout} from '../../services/user.service';

import loginStore from "../../store/loginStore";

// Muestra el componente para cerrar sesion
const CloseSesion = () => {
    const navigate = useNavigate();
    const setLogin = loginStore(state => state.setLogin);

    // Cierra la sesion
    const logoutUser = () => {
        // LLama al servidor para hacer logout
        logout().then(res => {
            // Limpia localstorage
            localStorage.clear();
            // Navega a raiz
            navigate('/');
            // Le dice a la variable global de login que no existe un login
            setLogin(false)
        }).catch(err => {

        })
    }

    return (
        <div className={'containerCloseSesion'} onClick={() => logoutUser()}>
            Cerrar sesión
            <img className={'iconCloseSesion'} alt={''} src={IconAssets.closeSesion}/>
        </div>
    )
}

export default CloseSesion;
